import styled from 'styled-components';

import { H1, H2 } from '../components/Atoms/Typography/Typography';
import { SiteGrid } from '../components/SiteGrid/SiteGrid';
import { desktopOnly } from './desktopHelpers';
import Wrapper from '../components/Wrapper/Wrapper';
import themeGet from './themeGet';

export const SitemapHero = styled.div`
  ${desktopOnly} {
    padding: 45px 0 40px;
  }
  padding: 80px 0 40px;
  background-color: ${themeGet('color.primary.graphite')};
`;

export const StyledSiteGrid = styled(SiteGrid)`
  grid-template-columns: repeat(1, 1fr);
`;

export const StyledWrapper = styled(Wrapper)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  ${desktopOnly} {
    gap: 20px;
  }
  gap: 15px;
`;

export const StyledSection = styled.section`
  padding: 30px 0 50px;
`;

export const StyledH1 = styled(H1)`
  font-size: 4.7rem;
  margin-top: ${themeGet('spacing.s6')};
  color: ${themeGet('color.primary.white')};
`;

export const StyledList = styled.ul`
  list-style-type: none;
  padding: 10px;

  & li:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const ListContainer = styled.div``;

export const StyledH2 = styled(H2)`
  font-size: 3rem;
  background-color: ${themeGet('color.primary.yellow')};
  margin: 20px 0;
  padding: 10px;
`;
